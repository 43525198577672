import * as React from "react"
import MasterLayout from "../layouts/master";
import { Input, Heading, Button, Text } from '@chakra-ui/react'

const pageStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
    display: "flex",
    flexDirection: "column",
    gap: "10px"
}

const RequestAccountDeletion = () => {
    return (
        <MasterLayout>
            <main style={pageStyles}>
                <Heading>Request for account deletion.</Heading>
                <Text fontSize='sm' color={"gray"}>Deleting your account will result in the loss of access to all the services, features, and benefits associated with your account. You will no longer be able to use any associated products or access your purchase history.</Text>
                <Text fontSize='sm' color={"gray"}>Account deletion is a significant decision. We recommend taking your time to consider the implications and consequences before proceeding. If you have any questions or need assistance, our support team is here to help.
                    Once your account is deleted, it is not possible to recover it or restore any of the associated data. If you change your mind or wish to use our services in the future, you will need to create a new account.</Text>
                <Input type="number" placeholder="Enter your phone no." focusBorderColor='green.400' />
                <Button
                    style={{ width: "fit-content" }}
                    fontSize={"0.86rem"}
                    fontWeight={"bold"}
                    color={"white"}
                    _hover={{
                        textDecoration: "none",
                        bg: "#71d387",
                    }}
                    bgColor={"#52bb6a"}
                >
                    Request Deletion
                </Button>
            </main>
        </MasterLayout>
    )
}

export default RequestAccountDeletion